import React from "react"
import { Link } from "gatsby"
import Clamp from "components/clamp"
import * as styles from "./footer.module.less"
import logo from "assets/images/logos/mono-ondark-168x192.svg"
import LinkedIn from "assets/images/icons/linkedin.svg"

export default function Footer() {
  const date = new Date()

  return (
    <footer className={styles.root}>
      <Clamp inset className={styles.deck}>
        <div className={styles.logo}>
          <img className={styles.logoShow} src={logo} alt="" />
          <div className={styles.logoCopy}>
            © {date.getFullYear()} GPI Capital.
          </div>
        </div>
        <div className={styles.info}></div>
        <div className={styles.info}>
          <div className={styles.infoHead}>New York office</div>
          <div className={styles.infoBody}>
            1345 Avenue of Americas
            <br />
            32nd Floor
            <br />
            New York NY 10105
          </div>
          <div className={styles.infoBody}>
            <a className={styles.infoLink} href="tel:+1-646-357-3144">
              +1 (646) 357 3144
            </a>
          </div>
          <div className={styles.infoFoot}>
            <a
              className={styles.infoMaps}
              href="https://goo.gl/maps/ZZGzUyhAUrGWP6Hs5"
              rel="noopener noreferrer"
              target="_blank"
            >
              Map
            </a>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.infoHead}>General inquires</div>
          <div className={styles.infoBody}>
            <a className={styles.infoLink} href="mailto:info@gpicap.com">
              info@gpicap.com
            </a>
          </div>
          <div className={styles.infoBody}>
            <Link className={styles.infoLink} to="/disclaimers">
              Disclaimers
            </Link>
          </div>
          <div className={styles.infoBody}>
            <Link className={styles.infoLink} to="/privacy">
              EU Privacy Policy
            </Link>
          </div>
          <div className={styles.infoFoot}>
            <a
              href="https://www.linkedin.com/company/gpi-capital/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img className={styles.infoLogo} src={LinkedIn} alt="" />
            </a>
          </div>
        </div>
      </Clamp>
    </footer>
  )
}
