import React from "react"
import { join } from "hooks/use-array"
import * as styles from "./header-open.module.less"

export default function HeaderOpen({ setState }) {
  const [maxWidth, setMaxWidth] = React.useState(false)
  React.useEffect(() => {
    if (typeof window === "object") {
      handler()
      window.addEventListener("resize", handler)
    }

    return () => {
      window.removeEventListener("resize", handler)
    }

    function handler() {
      setMaxWidth(window.innerWidth < 1280 ? true : false)
    }
  }, [])

  React.useEffect(() => {
    console.log(maxWidth)
  })

  return maxWidth ? (
    <div className={styles.root}>
      <button className={styles.btn} onClick={setState}>
        <span className={join("material-icons", styles.icon)}>menu</span>
      </button>
    </div>
  ) : null
}
