import React from "react"
import PropTypes from "prop-types"
import * as styles from "./main.module.less"

export default function Main({ children }) {
  return <main className={styles.root}>{children}</main>
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}
