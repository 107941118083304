//
//  Array utility functoins
//

/**
 *  Arrange target list using reference list by ID
 *
 *  @param {array} target
 *  @param {array} reference
 *  @return {array}
 */
export function arrange(target, reference) {
  return target.sort(
    (a, b) => reference.indexOf(a.id) - reference.indexOf(b.id)
  )
}

/**
 *  Filter target list using reference list by ID
 *
 *  @param {array} target
 *  @param {array} reference
 *  @return {array}
 */
export function filter(target, reference) {
  return target.filter(i => reference.includes(i.id))
}

/**
 *  Find object in array by ID
 *
 *  @param {array} target
 *  @param {string} id
 *  @return {object}
 */
export function find(target, id) {
  return target.find(i => i === id)
}

/**
 *  Confirm target list contains id
 * 
 *  @param {array} reference
 *  @param {string} id
 *  @return {boolean}
 */
export function includes(reference, id) {
  return reference.some(i => i.id === id)
}

/**
 *  Concatenate all arguments (space delimited)
 *
 *  @param {string} ... One or more strings
 *  @return {string}
 */
export function join() {
  return [...arguments].filter(Boolean).join(" ")
}
