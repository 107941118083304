import React from "react"
import { createPortal } from "react-dom"
import PropTypes from "prop-types"
import { join } from "hooks/use-array"
import * as styles from "./veil.module.less"

export default function Veil({ fullscreen, state, setState, children }) {
  React.useEffect(() => {
    if (state) document.body.style.overflow = "hidden"

    return () => {
      document.body.style.overflow = ""
    }
  }, [state])

  return (
    state &&
    createPortal(
      <div
        className={join(styles.root, fullscreen && styles.full)}
        onClick={setState}
      >
        {fullscreen && (
          <button
            className={join("material-icons", styles.btn)}
            onClick={setState}
          >
            close
          </button>
        )}
        {children}
      </div>,
      document.body
    )
  )
}

Veil.propTypes = {
  fullscreen: PropTypes.bool,
  state: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  setState: PropTypes.func,
  children: PropTypes.node,
}
