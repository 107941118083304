import React from "react"
import * as styles from "./header-user.module.less"

export default function HeaderUser() {
  return (
    <div className={styles.root}>
      <a
        className={styles.link}
        href="https://citcoone.citco.com/ui/login"
        rel="noopener noreferrer"
        target="_blank"
      >
        Fund I Investor Login
      </a>
      <a
        className={styles.link}
        href="https://investors.gpicap.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        Fund II Investor Login
      </a>
    </div>
  )
}
