// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-companies-js": () => import("./../../../src/pages/companies.js" /* webpackChunkName: "component---src-pages-companies-js" */),
  "component---src-pages-disclaimers-js": () => import("./../../../src/pages/disclaimers.js" /* webpackChunkName: "component---src-pages-disclaimers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-release-2020-02-12-philip-lo-js": () => import("./../../../src/pages/release/2020-02-12--philip-lo.js" /* webpackChunkName: "component---src-pages-release-2020-02-12-philip-lo-js" */),
  "component---src-pages-release-2022-01-14-promotions-js": () => import("./../../../src/pages/release/2022-01-14--promotions.js" /* webpackChunkName: "component---src-pages-release-2022-01-14-promotions-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

