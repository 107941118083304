import React from "react"
import { Link } from "gatsby"
import Veil from "components/veil"
import { join } from "hooks/use-array"
import * as styles from "./header-menu.module.less"

export default function HeaderMenu({ state, setState }) {
  const [maxWidth, setMaxWidth] = React.useState(false)
  React.useEffect(() => {
    if (typeof window === "object") {
      handler()
      window.addEventListener("resize", handler)
    }

    return () => {
      window.removeEventListener("resize", handler)
    }

    function handler() {
      setMaxWidth(window.innerWidth < 1280 ? true : false)
    }
  }, [])

  return (
    <nav className={join(styles.root, maxWidth && state && styles.active)}>
      <Link
        className={styles.link}
        activeClassName={styles.active}
        to="/"
        onClick={setState}
      >
        Home
      </Link>
      <Link
        className={styles.link}
        activeClassName={styles.active}
        to="/#approach"
        onClick={setState}
      >
        Our approach
      </Link>
      {/* <Link
        className={styles.link}
        activeClassName={styles.active}
        to="/team"
        onClick={setState}
      >
        Our team
      </Link> */}
      <Link
        className={styles.link}
        activeClassName={styles.active}
        to="/companies"
        onClick={setState}
      >
        Our investments
      </Link>
      <Link
        className={styles.link}
        activeClassName={styles.active}
        to="/news"
        onClick={setState}
      >
        News
      </Link>
      <a
        className={join(styles.link, styles.user)}
        href="https://citcoone.citco.com/ui/login"
        rel="noopener noreferrer"
        target="_blank"
      >
        Fund I Investor Login
      </a>
      <a
        className={join(styles.link, styles.user)}
        href="https://investors.gpicap.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        Fund II Investor Login
      </a>
      {maxWidth && state && <Veil state={state} setState={setState} />}
    </nav>
  )
}
