// extracted by mini-css-extract-plugin
export var deck = "footer-module--deck--S0P86";
export var info = "footer-module--info--ZAY+2";
export var infoBody = "footer-module--infoBody--mODFN";
export var infoFoot = "footer-module--infoFoot--LSPcn";
export var infoHead = "footer-module--infoHead--r3AvX";
export var infoLink = "footer-module--infoLink--4JU4N";
export var infoLogo = "footer-module--infoLogo--DhtNE";
export var infoMaps = "footer-module--infoMaps--EgvYs";
export var logo = "footer-module--logo--OglEE";
export var logoCopy = "footer-module--logoCopy--w7UrZ";
export var logoShow = "footer-module--logoShow--tTdbu";
export var root = "footer-module--root--Enmbp";