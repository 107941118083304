import React from "react"
import { Link } from "gatsby"
import * as styles from "./header-logo.module.less"
import logo from "assets/images/logos/poly-onlite-168x192.svg"

export default function HeaderLogo() {
  return (
    <div className={styles.root}>
      <Link to="/">
        <img className={styles.img} src={logo} alt="" />
      </Link>
    </div>
  )
}
