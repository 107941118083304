import React from "react"
import PropTypes from "prop-types"
import { join } from "hooks/use-array"
import * as styles from "./clamp.module.less"

export default function Clamp({ inset, className, children }) {
  return <div className={join(styles.root, inset && styles.inset, className)}>{children}</div>
}

Clamp.propTypes = {
  inset: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
}
