import React from "react"
import HeaderLogo from "./logo"
import HeaderMenu from "./menu"
import HeaderOpen from "./open"
import HeaderUser from "./user"
import Clamp from "components/clamp"
import { join } from "hooks/use-array"
import * as styles from "./header.module.less"

export default function Header() {
  const [scrolledState, setScrolledState] = React.useState(false)
  React.useEffect(() => {
    if (typeof window === "object") window.addEventListener("scroll", handler)

    return () => {
      window.removeEventListener("scroll", handler)
    }

    function handler() {
      setScrolledState(!!window.scrollY)
    }
  }, [])

  const [menuState, setMenuState] = React.useState(false)
  function menuHandler() {
    setMenuState(i => !i)
  }

  return (
    <header className={join(styles.root, scrolledState && styles.active)}>
      <Clamp className={styles.deck}>
        <HeaderLogo />
        <HeaderMenu state={menuState} setState={menuHandler} />
        <HeaderUser />
        <HeaderOpen setState={menuHandler} />
      </Clamp>
    </header>
  )
}
