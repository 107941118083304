import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Footer from "components/footer"
import Header from "components/header"
import Main from "components/main"
import "./styles.less"

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        {/* HubSpot embed code*/}
        <script
          id="hs-script-loader"
          src="https://js.hs-scripts.com/43761637.js"
          async
          defer
        />
      </Helmet>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

Layout.prototype = {
  children: PropTypes.node.isRequired,
}
